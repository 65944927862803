var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.notificationList,"fields":_vm.fields,"busy":_vm.isLoadingComponent,"show-empty":"","responsive":""},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-secondary my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v("Loading...")])],1)]},proxy:true},{key:"cell(no)",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s((_vm.currentPageList - 1) * 10 + index + 1)+" ")]}},{key:"cell(isChangeable)",fn:function(ref){
var item = ref.item;
return [(item.isChangeable)?_c('div',[_c('feather-icon',{staticClass:"ml-3 text-primary",attrs:{"size":"1.5x","icon":"CheckIcon"}})],1):_c('div',[_c('feather-icon',{staticClass:"ml-3 text-danger",attrs:{"size":"1.5x","icon":"XIcon"}})],1)]}},{key:"cell(isSentToWhatsApp)",fn:function(ref){
var item = ref.item;
return [(item.isSentToWhatsApp)?_c('div',[_c('feather-icon',{staticClass:"ml-2 text-primary",attrs:{"size":"1.5x","icon":"CheckIcon"}})],1):_c('div',[_c('feather-icon',{staticClass:"ml-2 text-danger",attrs:{"size":"1.5x","icon":"XIcon"}})],1)]}},{key:"cell(isSentToEmail)",fn:function(ref){
var item = ref.item;
return [(item.isSentToEmail)?_c('div',[_c('feather-icon',{staticClass:"ml-1 text-primary",attrs:{"size":"1.5x","icon":"CheckIcon"}})],1):_c('div',[_c('feather-icon',{staticClass:"ml-1 text-danger",attrs:{"size":"1.5x","icon":"XIcon"}})],1)]}},{key:"cell(isSentToWebsite)",fn:function(ref){
var item = ref.item;
return [(item.isSentToWebsite)?_c('div',[_c('feather-icon',{staticClass:"ml-1 text-primary",attrs:{"size":"1.5x","icon":"CheckIcon"}})],1):_c('div',[_c('feather-icon',{staticClass:"ml-1 text-danger",attrs:{"size":"1.5x","icon":"XIcon"}})],1)]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-dropdown',{attrs:{"variant":"danger","size":"sm","boundary":"viewport"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_vm._v(" Action ")]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"to":'/update-notification/' + item.id}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"EditIcon"}}),_vm._v(" Update ")],1)],1)]}}])}),(_vm.resultData.perPage > 0)?_c('div',{staticClass:"mt-3 d-flex justify-content-between align-items-center"},[_c('small',[_vm._v("Showing "+_vm._s((_vm.resultData.page - 1) * 10 + 1)+" to "+_vm._s((_vm.resultData.page - 1) * 10 + _vm.rows)+" from "+_vm._s(_vm.resultData.total)+" ")]),_c('b-pagination',{staticClass:"justifvy-content-end",attrs:{"total-rows":_vm.resultData.total,"per-page":_vm.resultData.perPage,"aria-controls":"my-table"},on:{"change":_vm.getData},model:{value:(_vm.currentPageList),callback:function ($$v) {_vm.currentPageList=$$v},expression:"currentPageList"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }